import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import CenterOfExcellence from "./components/CenterOfExcellence";
// import BusinessQuestionnaire from "./components/BusinessQuestionnaire";
// import MonitoringandTesting from "./components/MonitoringandTesting";
import CAIQAssessment from "./components/CAIQAssessment";

import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={CenterOfExcellence} />
          <Route path="/vendorsecurityassessment" component={CAIQAssessment} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
