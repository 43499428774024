import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import ImportScript from "../shared/components/ImportScript";

function CAIQAssessment() {
  return (
    <>
      <SrrHeader
        heading="Vendor Security Assessment"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="VENDOR SECURITY ASSESSMENT"
      />

      <section className="form-section my-3 text-justify">
        <ImportScript
          src={process.env.REACT_APP_IMPARTNER_COE_VENDOR_SECURITY_ASSESSMENT}
          pagId={
            process.env.REACT_APP_IMPARTNER_COE_VENDOR_SECURITY_ASSESSMENT_PGID
          }
        />
      </section>
    </>
  );
}

export default CAIQAssessment;
