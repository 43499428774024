import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import privacy_policy from "../shared/images/privacy_policy.png";
import metrics from "../shared/images/metrics.png";
// import write from "../shared/images/write.png";

function CenterOfExcellence() {
  return (
    <>
      <div className="banner centerofexcellence-banner"></div>
      <div className="heading padding-tb-20">
        <Container>
          <h1>Center Of Excellence</h1>
          <h2>
            To complete Impartner's vendor security assessment, click on the
            button below.
          </h2>
        </Container>
      </div>
      <Container className="my-5">
        <Row className="ds-flex justify-content-center">
          {/* <Col xs={12} md={4} lg={4}>
            <a className="card-tc" href="/businessquestionnaire">
              <img
                src={privacy_policy}
                width="50px"
                alt="businessquestionnaire"
              />
              <p className="margin-top--10 fw--400">Business Questionnaire</p>
            </a>
          </Col> */}
          <Col xs={12} md={4} lg={4}>
            <a className="card-tc" href="/vendorsecurityassessment">
              <img
                src={metrics}
                width="50px"
                alt="vendor security assessment"
              />
              <p className="margin-top--10 fw--400">
                Vendor Security Assessment
              </p>
            </a>
          </Col>
          {/* <Col xs={12} md={4} lg={4}>
            <a className="card-tc" href="/monitoringandtesting">
              <img src={write} width="50px" alt="monitoringandtesting" />
              <p className="margin-top--10 fw--400">Monitoring and Testing</p>
            </a>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default CenterOfExcellence;
